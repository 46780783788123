import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { color, fontSize, lineHeight, space, media } from "../styles/constants"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Layout from "../components/Layout"

import { MainWrapper } from '../components/Wrapper'
import { SiteGrid, Container } from '../components/Container'
import Header from "../components/Header"
import Footer from "../components/Footer"
import { SectionHeader, BodyText, BlockContentQuote, BlockHeading, BlockContentParagraph, BlockContentUl, BlockContentOl, LiText } from "../components/Type"
import { SiteAnchor } from "../components/Link"


export const query = graphql`
    query(
        $slug: String!
    ){
        contentfulBlog(
            slug:{
                eq: $slug
            }
        ){
            title
            publishedDate (formatString: "LL")
            preview{
                preview
            }
            body{
                raw
            }
        }

        site{
            siteMetadata{
                pageTitleSuffix
            }
        }
    }
`

const CondensedSiteGrid = styled(SiteGrid)`
    grid-row-gap: 5vh;
`

const LiTextCondensed = styled(LiText)`
    p {
        margin: ${space.p50} 0;
    }
`

// Move all three headings to Type
const BlockHeading1 = styled.h1`
    font-family: 'Slabo 27px';
    font-size: ${fontSize.f350};
    color: ${color.white};
    font-weight: 400;
    line-height: ${lineHeight.lh350};
    margin: ${space.p400} 0 ${space.p100} 0;
`
const BlockHeading2 = styled.h2`
    font-family: 'IBM Plex Sans';
    font-size: ${fontSize.f250};
    color: ${color.white};
    font-weight: 600;
    line-height: ${lineHeight.lh250};
    margin: 0;
    margin: ${space.p400} 0 ${space.p100} 0;
`
const BlockHeading3 = styled.h3`
    font-family: 'IBM Plex Sans';
    font-size: ${fontSize.f175};
    color: ${color.white};
    font-weight: 400;
    line-height: ${lineHeight.lh175};
    margin: ${space.p400} 0 ${space.p100} 0;
`

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <BodyText>{children}</BodyText>,
        [BLOCKS.UL_LIST]: (node, children) => <BlockContentUl>{children}</BlockContentUl>,
        [BLOCKS.OL_LIST]: (node, children) => <BlockContentOl>{children}</BlockContentOl>,
        [BLOCKS.QUOTE]: (node, children) => <BlockContentQuote>{children}</BlockContentQuote>,
        [BLOCKS.HEADING_1]: (node, children) => <BlockHeading1>{children}</BlockHeading1>,
        [BLOCKS.HEADING_2]: (node, children) => <BlockHeading2>{children}</BlockHeading2>,
        [BLOCKS.HEADING_3]: (node, children) => <BlockHeading3>{children}</BlockHeading3>,
        [BLOCKS.LIST_ITEM]: (node, children) => <LiTextCondensed>{children}</LiTextCondensed>,
        [INLINES.HYPERLINK]: (node, children) => {
            return(<SiteAnchor href={node.data.uri} target="_blank" rel="noreferrer">{children}</SiteAnchor>)
        }
    }
}

const PostHeading = styled.h2`
    color: ${color.white};
    font-family: 'IBM Plex Sans', sans-serif;
`

const PostContentArea = styled(Container)`
`

const FrontMatterArea = styled.div`
    text-align: left;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 2;
        grid-column-end: 8;
    `}

    ${media.xl`
        grid-column-start: 3;
        grid-column-end: 11;
    `}
`

const PostBodyArea = styled.div`
    text-align: left;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 2;
        grid-column-end: 8;
    `}

    ${media.xl`
        grid-column-start: 3;
        grid-column-end: 11;
    `}
`

const DateText = styled(BodyText)`
    color: ${color.lightGrey};
    opacity: 0.5;
    margin-top: ${space.p50};
`

const PreviewText = styled(BodyText)`
    font-style: italic;
    color: ${color.lightGrey};
`

const PageHeader = styled(SectionHeader)`
    text-align: center;
`

const BlogTemplatePage = (props) => {
    const pageTitle = props.data.contentfulBlog.title + props.data.site.siteMetadata.pageTitleSuffix

    return (
        <Layout title={pageTitle}>
            <MainWrapper />
            <CondensedSiteGrid>
                <Header />
                <PostContentArea>
                    <FrontMatterArea>
                        <SectionHeader>Blog</SectionHeader>
                        <PostHeading>{props.data.contentfulBlog.title}</PostHeading>
                        <DateText>{props.data.contentfulBlog.publishedDate}</DateText>
                        <PreviewText>{props.data.contentfulBlog.preview.preview}</PreviewText>
                    </FrontMatterArea>

                    <PostBodyArea>{
                        renderRichText(props.data.contentfulBlog.body, options)
                    }
                    </PostBodyArea>
                </PostContentArea>
                <Footer />
            </CondensedSiteGrid>
        </Layout>
    )
}

export default BlogTemplatePage